import type { ApolloCache, DocumentNode, FetchResult } from '@apollo/client';
import PaginationHelper, {
  PaginationUpdateStrategy
} from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import type {
  MessagePageOrReplyPageAndParams,
  MessageReplyPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import type { CustomRouter } from '@aurora/shared-client/routes/useCustomRouter';
import type { EndUserRouter } from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Entity,
  Message,
  MessageConstraints,
  MessageEdge,
  MessageSorts,
  PageInfo,
  ReplyMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ImageAssociationType,
  RepliesFormat,
  SortDirection,
  VideoAssociationType
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { ContextMessageFragment } from '@aurora/shared-generated/types/graphql-types';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { Tenant } from '@aurora/shared-types/tenant';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import messageDescendantsFragment from '../../../components/messages/MessageDescendants.fragment.graphql';
import messageDescendantsQuery from '../../../components/messages/MessageDescendants.query.graphql';
import messageRepliesFragment from '../../../components/messages/MessageReplies.fragment.graphql';
import messageRepliesQuery from '../../../components/messages/MessageReplies.query.graphql';
import type { MessageTypes } from '../../../components/messages/MessageView/types';
import messageViewsQuery from '../../../components/messages/MessageViews.query.graphql';
import { getCachedEntityViewVariables } from '../../../components/useEntityViewQuery';
import { ApolloQueryCacheKey, ItemType, MessageViewVariant } from '../../../types/enums';
import type {
  CreateBlogArticleCommentMutation,
  CreateBlogArticleMutation,
  CreateForumReplyMutation,
  CreateForumTopicMutation,
  CreateIdeaCommentMutation,
  CreateIdeaContentMutation,
  CreateOccasionReplyMutation,
  CreateTkbArticleCommentMutation,
  CreateTkbArticleMutation,
  MessageActionFragment,
  MessageActionMenuFragment,
  MessageBasicFieldsFragment,
  MessageBoardFragment,
  MessageConversationFragment,
  MessageDescendantsFragment,
  MessageDescendantsQueryVariables,
  MessageLinkFragment,
  MessageRepliesCountFragment,
  MessageRepliesFragment,
  MessageRepliesQueryVariables,
  MessageReplyButtonFragment,
  MessageViewFragment,
  MessageViewsQuery,
  MessageViewsQueryVariables,
  UpdateBlogArticleCommentMutation,
  UpdateBlogArticleMutation,
  UpdateForumReplyMutation,
  UpdateForumTopicMutation,
  UpdateIdeaCommentMutation,
  UpdateIdeaMutation,
  UpdateTkbArticleCommentMutation,
  UpdateTkbArticleMutation
} from '../../../types/graphql-types';
import ConversationStyleBehaviorHelper from '../../boards/ConversationStyleBehaviorHelper';
import placeholderAcceptedSolutionMessage from '../../placeholders/placeholderAcceptedSolutionMessage';
import placeholderMessage from '../../placeholders/placeholderMessage';
import placeholderMessageConnection from '../../placeholders/placeholderMessageConnection';
import placeholderMessageEdge from '../../placeholders/placeholderMessageEdge';
import placeholderPageInfo from '../../placeholders/placeholderPageInfo';

/**
 * Updates the new message to the replies field of the message.
 * Uses MessageReplies fragment.
 *
 * @param tenant the current tenant.
 * @param idToUpdateReplyCount The parent message's cache id.
 * @param cache the ApolloClient cache.
 * @param fragment the fragment on cache which is to be updated.
 * @param fragmentName Name of the fragment which is to be updated.
 * @param cachedVariables Cached variables for MessageRepliesQuery
 * @param createMessage The create message mutation's response object.
 * @param parentMessage Parent message to which this reply is added to.
 * @param updateStrategy PaginationUpdateStrategy value.
 */
function updateRepliesCache<T>(
  tenant: Tenant,
  idToUpdateReplyCount: string,
  cache: ApolloCache<T>,
  fragment: DocumentNode,
  fragmentName: string,
  cachedVariables: MessageRepliesQueryVariables,
  createMessage,
  parentMessage,
  updateStrategy: PaginationUpdateStrategy
) {
  const variables: MessageRepliesQueryVariables = {
    ...cachedVariables,
    id: idToUpdateReplyCount
  };

  // Read the data from our cache for this query.
  const cachedFragment = cache.readFragment<MessageRepliesFragment>({
    id: idToUpdateReplyCount,
    fragment,
    fragmentName,
    variables
  });

  if (!cachedFragment) {
    return;
  }

  const { replies } = cachedFragment;
  const edges = replies?.edges ?? [];
  const pageInfo = (replies?.pageInfo ?? placeholderPageInfo) as PageInfo;

  const newEdge: MessageEdge = {
    node: placeholderMessage(
      tenant,
      {
        ...(createMessage as Message),
        board: parentMessage.board,
        conversation: parentMessage.conversation,
        repliesCount: 0,
        replies: placeholderMessageConnection({ edges: [] })
      },
      true
    ),
    cursor: null
  };

  const data = {
    ...cachedFragment,
    replies: {
      edges:
        createMessage && parentMessage && newEdge
          ? PaginationHelper.mergeItems(updateStrategy, edges, [newEdge])
          : edges,
      pageInfo,
      __typename: 'MessageConnection'
    }
  };

  cache.writeFragment({
    id: idToUpdateReplyCount,
    fragment: messageRepliesFragment,
    fragmentName,
    data,
    variables
  });
  return data;
}

/**
 * Updates the new message to the descendants field of the message.
 * Uses MessageDescendants fragment.
 *
 * @param tenant the current tenant.
 * @param idToUpdateReplyCount The parent message's cache id.
 * @param cache the ApolloClient cache.
 * @param fragment the fragment on cache which is to be updated.
 * @param fragmentName Name of the fragment which is to be updated.
 * @param cachedVariables Cached variables for MessageDescendantsQuery
 * @param createMessage The create message mutation's response object.
 * @param parentMessage Parent message to which this reply is added to.
 * @param updateStrategy PaginationUpdateStrategy value.
 */
function updateDescendantsCache<T>(
  tenant: Tenant,
  idToUpdateReplyCount: string,
  cache: ApolloCache<T>,
  fragment: DocumentNode,
  fragmentName: string,
  cachedVariables: MessageDescendantsQueryVariables,
  createMessage,
  parentMessage,
  updateStrategy: PaginationUpdateStrategy
) {
  const variables: MessageDescendantsQueryVariables = {
    ...cachedVariables,
    id: idToUpdateReplyCount
  };

  // Read the data from our cache for this query.
  const cachedFragment = cache.readFragment<MessageDescendantsFragment>({
    id: idToUpdateReplyCount,
    fragment,
    fragmentName,
    variables
  });

  if (!cachedFragment) {
    return;
  }

  const { descendants } = cachedFragment;
  const edges = descendants?.edges ?? [];
  const pageInfo = (descendants?.pageInfo ?? placeholderPageInfo) as PageInfo;

  const newEdge: MessageEdge = {
    node: placeholderMessage(
      tenant,
      {
        ...(createMessage as Message),
        board: parentMessage.board,
        conversation: parentMessage.conversation,
        repliesCount: 0
      },
      true
    ),
    cursor: null
  };

  const data = {
    ...cachedFragment,
    descendants: {
      edges:
        createMessage && parentMessage && newEdge
          ? PaginationHelper.mergeItems(updateStrategy, edges, [newEdge])
          : edges,
      pageInfo,
      __typename: 'MessageConnection'
    }
  };

  cache.writeFragment({
    id: idToUpdateReplyCount,
    fragment: messageDescendantsFragment,
    fragmentName,
    data,
    variables
  });
  return data;
}

/**
 * * Build sorts for solutions list. This method lives here to avoid a circular dependency.
 */
function buildSolutionsListSorts(): MessageSorts {
  return {
    postTime: {
      direction: SortDirection.Asc
    }
  };
}

/**
 * Build constraints for solutions list. This method lives here to avoid a circular dependency.
 * @param message - the message
 */
function buildSolutionsListConstraints(message: Entity): MessageConstraints {
  return {
    topicId: {
      eq: message.id
    },
    solution: {
      eq: true
    }
  };
}

function getAddReplyMessageFromResult(
  fetchResult: FetchResult<
    | CreateForumTopicMutation
    | CreateForumReplyMutation
    | CreateBlogArticleMutation
    | CreateBlogArticleCommentMutation
    | CreateTkbArticleMutation
    | CreateTkbArticleCommentMutation
    | CreateIdeaContentMutation
    | CreateIdeaCommentMutation
    | CreateOccasionReplyMutation
  >
) {
  if ((fetchResult?.data as CreateForumTopicMutation).createForumTopic != undefined) {
    return (fetchResult.data as CreateForumTopicMutation).createForumTopic?.result;
  } else if ((fetchResult?.data as CreateBlogArticleMutation).createBlogArticle != undefined) {
    return (fetchResult.data as CreateBlogArticleMutation).createBlogArticle?.result;
  } else if ((fetchResult?.data as CreateTkbArticleMutation).createTkbArticle != undefined) {
    return (fetchResult.data as CreateTkbArticleMutation).createTkbArticle?.result;
  } else if ((fetchResult?.data as CreateForumReplyMutation).createForumReply != undefined) {
    return (fetchResult.data as CreateForumReplyMutation).createForumReply?.result;
  } else if (
    (fetchResult?.data as CreateBlogArticleCommentMutation).createBlogArticleComment != undefined
  ) {
    return (fetchResult.data as CreateBlogArticleCommentMutation).createBlogArticleComment?.result;
  } else if (
    (fetchResult?.data as CreateTkbArticleCommentMutation).createTkbArticleComment != undefined
  ) {
    return (fetchResult.data as CreateTkbArticleCommentMutation).createTkbArticleComment?.result;
  } else if ((fetchResult?.data as CreateIdeaContentMutation).createIdea != undefined) {
    return (fetchResult.data as CreateIdeaContentMutation).createIdea?.result;
  } else if ((fetchResult?.data as CreateIdeaCommentMutation).createIdeaComment != undefined) {
    return (fetchResult.data as CreateIdeaCommentMutation).createIdeaComment?.result;
  } else if ((fetchResult?.data as CreateOccasionReplyMutation).createOccasionReply != undefined) {
    return (fetchResult.data as CreateOccasionReplyMutation).createOccasionReply?.result;
  }
}

/**
 * Adds a new message to the Apollo Client Cache, which will trigger all observable queries to update.
 *
 * @param tenant the current tenant
 * @param parentMessage the parent message to add the reply to
 * @param cache the ApolloClient cache
 * @param fetchResult the `FetchResult` from the create message mutation that contains the new reply
 * @param updateStrategy the update strategy; controls how the new message is added to the existing list
 * @param repliesFormat the repliesFormat followed for that user/node.
 */
function addReplyToMessageInCache<T>(
  tenant: Tenant,
  parentMessage: MessageBasicFieldsFragment & MessageConversationFragment & MessageBoardFragment,
  cache: ApolloCache<T>,
  fetchResult: FetchResult<
    | CreateForumTopicMutation
    | CreateForumReplyMutation
    | CreateBlogArticleMutation
    | CreateBlogArticleCommentMutation
    | CreateTkbArticleMutation
    | CreateTkbArticleCommentMutation
    | CreateIdeaContentMutation
    | CreateIdeaCommentMutation
    | CreateOccasionReplyMutation
  >,
  updateStrategy = PaginationUpdateStrategy.APPEND,
  repliesFormat = RepliesFormat.Threaded
): void {
  const createMessage = getAddReplyMessageFromResult(fetchResult);
  if (parentMessage && createMessage?.depth === 1) {
    const id = cache.identify(parentMessage);
    const { id: parentId, depth, revisionNum } = parentMessage;
    const isTopic = depth === 0;
    const cacheKey =
      (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
      `:${parentId}:${revisionNum}`;
    let cachedVariables: MessageRepliesQueryVariables | MessageDescendantsQueryVariables;
    if (repliesFormat === RepliesFormat.Threaded) {
      cachedVariables = getCachedEntityViewVariables<
        ItemType.MESSAGE,
        MessageRepliesQueryVariables
      >(
        cache,
        ItemType.MESSAGE,
        { type: MessageViewVariant.STANDARD },
        messageRepliesQuery,
        cacheKey
      );
      updateRepliesCache(
        tenant,
        id,
        cache,
        messageRepliesFragment,
        'MessageReplies',
        cachedVariables,
        createMessage,
        parentMessage,
        updateStrategy
      );
    } else {
      cachedVariables = getCachedEntityViewVariables<
        ItemType.MESSAGE,
        MessageDescendantsQueryVariables
      >(
        cache,
        ItemType.MESSAGE,
        { type: MessageViewVariant.STANDARD },
        messageDescendantsQuery,
        cacheKey
      );
      updateDescendantsCache(
        tenant,
        id,
        cache,
        messageDescendantsFragment,
        'MessageDescendants',
        cachedVariables,
        createMessage,
        parentMessage,
        updateStrategy
      );
    }
  }
}

function getUpdateReplyMessageFromResult(
  fetchResult: FetchResult<
    | UpdateForumTopicMutation
    | UpdateForumReplyMutation
    | UpdateBlogArticleMutation
    | UpdateBlogArticleCommentMutation
    | UpdateTkbArticleMutation
    | UpdateTkbArticleCommentMutation
    | UpdateIdeaMutation
    | UpdateIdeaCommentMutation
  >
) {
  if ((fetchResult?.data as UpdateForumReplyMutation).updateForumReply != undefined) {
    return (fetchResult.data as UpdateForumReplyMutation).updateForumReply?.result;
  } else if (
    (fetchResult?.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment != undefined
  ) {
    return (fetchResult.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment?.result;
  } else if (
    (fetchResult?.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment != undefined
  ) {
    return (fetchResult.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment?.result;
  } else if ((fetchResult?.data as UpdateForumReplyMutation).updateForumReply != undefined) {
    return (fetchResult?.data as UpdateForumReplyMutation).updateForumReply?.result;
  } else if (
    (fetchResult?.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment != undefined
  ) {
    return (fetchResult?.data as UpdateBlogArticleCommentMutation).updateBlogArticleComment?.result;
  } else if (
    (fetchResult?.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment != undefined
  ) {
    return (fetchResult?.data as UpdateTkbArticleCommentMutation).updateTkbArticleComment?.result;
  }
}

/**
 * Update a new message to the Apollo Client Cache, which will trigger all observable queries to update.
 *
 * @param tenant the current tenant
 * @param message the message which is edited
 * @param cache the ApolloClient cache
 * @param fetchResult the `FetchResult` from the update message mutation that contains the edited message
 */
function updateReplyToMessageInCache<T>(
  tenant: Tenant,
  message: Message,
  cache: ApolloCache<T>,
  fetchResult: FetchResult<
    | UpdateForumTopicMutation
    | UpdateForumReplyMutation
    | UpdateBlogArticleMutation
    | UpdateBlogArticleCommentMutation
    | UpdateTkbArticleMutation
    | UpdateTkbArticleCommentMutation
  >
): void {
  const updateMessage = getUpdateReplyMessageFromResult(fetchResult);
  const { parent } = message as ReplyMessage;
  const id = cache.identify(parent);
  const { id: parentId, revisionNum } = parent as Message;
  const { depth } = updateMessage;
  const isTopic = depth === 0;
  const cacheKey =
    (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${parentId}:${revisionNum}`;
  const fragment = messageRepliesFragment;
  const fragmentName = 'MessageReplies';
  const cachedVariables = getCachedEntityViewVariables<
    ItemType.MESSAGE,
    MessageRepliesQueryVariables
  >(cache, ItemType.MESSAGE, { type: MessageViewVariant.STANDARD }, messageRepliesQuery, cacheKey);
  const variables: MessageRepliesQueryVariables = {
    ...cachedVariables,
    id
  };

  // Read the data from our cache for this query.
  const cachedFragment = cache.readFragment<MessageRepliesFragment>({
    id,
    fragment,
    fragmentName,
    variables
  });

  const { replies } = cachedFragment;
  const edges = replies?.edges ?? [];
  const pageInfo = (replies?.pageInfo ?? placeholderPageInfo) as PageInfo;

  let newEdge: MessageEdge;
  if (message && updateMessage) {
    newEdge = {
      node: placeholderMessage(
        tenant,
        {
          ...(updateMessage as Message),
          board: message.board,
          conversation: message.conversation
        },
        true
      ),
      cursor: null
    };
  }
  const data = {
    ...cachedFragment,
    replies: {
      edges: PaginationHelper.mergeItems(PaginationUpdateStrategy.REPLACE, edges, [newEdge]),
      pageInfo,
      __typename: 'MessageConnection'
    }
  };

  cache.writeFragment({
    id,
    fragment: messageRepliesFragment,
    fragmentName,
    data,
    variables
  });
}

/**
 * Update the solutions list cache after a message solution state change.
 *
 * @param tenant The current tenant
 * @param cache The apollo cache
 * @param message The updated message
 * @param isSolution Whether the message is a solution (after the change)
 * @param repliesFormat RepliesFormat of that particular node.
 */
function updateSolutionsListCache(
  tenant: Tenant,
  cache: ApolloCache<{}>,
  message: Entity & MessageConversationFragment & MessageBoardFragment,
  isSolution: boolean,
  repliesFormat: RepliesFormat
): MessageViewsQuery {
  const cachedVariables =
    repliesFormat === RepliesFormat.Threaded
      ? getCachedEntityViewVariables<ItemType.MESSAGE, MessageRepliesQueryVariables>(
          cache,
          ItemType.MESSAGE,
          { type: MessageViewVariant.STANDARD },
          messageViewsQuery,
          'MessageSolutions',
          true
        )
      : getCachedEntityViewVariables<ItemType.MESSAGE, MessageDescendantsQueryVariables>(
          cache,
          ItemType.MESSAGE,
          { type: MessageViewVariant.STANDARD },
          messageViewsQuery,
          'MessageSolutions',
          true
        );

  const constraints: MessageConstraints = buildSolutionsListConstraints(
    message.conversation?.topic
  );

  const sorts: MessageSorts = buildSolutionsListSorts();

  const variables: MessageViewsQueryVariables = {
    ...cachedVariables,
    constraints,
    sorts
  };

  const cacheResult = cache.readQuery<MessageViewsQuery, MessageViewsQueryVariables>({
    query: messageViewsQuery,
    variables
  });

  if (!cacheResult) {
    return;
  }

  const { messages } = cacheResult;
  let { edges: updatedEdges } = messages;
  if (isSolution) {
    updatedEdges = [
      ...messages.edges,
      placeholderMessageEdge(tenant, {
        node: placeholderAcceptedSolutionMessage(tenant, {
          ...(message as Partial<Message>),
          solution: true
        })
      })
    ];
  } else {
    updatedEdges = messages.edges.filter(edge => edge?.node?.id !== message?.id);
  }

  const data: MessageViewsQuery = {
    messages: placeholderMessageConnection({
      ...messages,
      edges: updatedEdges as MessageEdge[],
      pageInfo: placeholderPageInfo(messages?.pageInfo)
    })
  };

  cache.writeQuery<MessageViewsQuery, MessageViewsQueryVariables>({
    query: messageViewsQuery,
    variables,
    data
  });

  return data;
}

/**
 * Update the message cache after a message solution state change.
 *
 * @param cache The apollo cache
 * @param message The updated message
 * @param isSolution Whether the message is a solution (after the change)
 */
function updateSolutionMessageCache(
  cache: ApolloCache<{}>,
  message: Entity,
  isSolution: boolean
): void {
  const cacheId = cache.identify(message);
  cache.modify({
    id: cacheId,
    fields: {
      solution() {
        return isSolution;
      },
      conversation(currentValue) {
        return { ...currentValue, solved: isSolution };
      }
    }
  });
}

/**
 * Removes a reply from a parent message in the Apollo cache.
 *
 * @param tenant The current tenant.
 * @param cache The apollo cache.
 * @param parentMessage The parent message of the reply.
 * @param messageToRemove The reply that should be removed from the parent message's replies.
 */
function removeCachedReplyFromMessage<T>(
  tenant: Tenant,
  cache: ApolloCache<T>,
  parentMessage: MessageBasicFieldsFragment & MessageRepliesCountFragment & MessageBoardFragment,
  messageToRemove: MessageBasicFieldsFragment &
    MessageRepliesCountFragment &
    MessageBoardFragment &
    MessageConversationFragment
): void {
  const id = cache.identify(parentMessage);
  const { id: parentId, revisionNum: parentRevision } = parentMessage;
  const isParentTopic = messageToRemove.depth - 1 === 0;
  const cacheKey =
    (isParentTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${parentId}:${parentRevision}`;
  const fragment = messageRepliesFragment;
  const fragmentName = 'MessageReplies';
  const variables = getCachedEntityViewVariables<ItemType.MESSAGE, MessageRepliesQueryVariables>(
    cache,
    ItemType.MESSAGE,
    { type: MessageViewVariant.STANDARD },
    messageRepliesQuery,
    cacheKey
  );
  const cachedParentMessage = cache.readFragment<MessageRepliesFragment>({
    id,
    fragment,
    fragmentName,
    variables
  });

  if (!cachedParentMessage) {
    return;
  }

  const originalEdges = cachedParentMessage?.replies?.edges ?? [];
  const { repliesCount } = parentMessage;
  const currentMessageReplyCount = messageToRemove?.repliesCount ?? 0;
  const updatedEdges = originalEdges.filter(edge => edge?.node?.id !== messageToRemove?.id);

  const data = {
    ...cachedParentMessage,
    repliesCount: repliesCount - currentMessageReplyCount - 1,
    replies: placeholderMessageConnection({
      edges: updatedEdges as MessageEdge[],
      pageInfo: placeholderPageInfo(cachedParentMessage?.replies?.pageInfo)
    })
  };

  cache.writeFragment<MessageRepliesFragment>({
    id,
    fragment,
    fragmentName,
    data,
    variables
  });

  updateSolutionsListCache(tenant, cache, messageToRemove, false, RepliesFormat.Threaded);
  updateSolutionMessageCache(cache, messageToRemove, false);
}

/**
 * Removes the deleted message from the topic's descendants field.
 *
 * @param tenant The current tenant.
 * @param cache The apollo cache.
 * @param topic The topic/root message of the conversation where the reply exists.
 * @param messageToRemove The reply that should be removed from the root/topic message's descendants.
 */
function removeCachedDescendantFromTopicMessage<T>(
  tenant: Tenant,
  cache: ApolloCache<T>,
  topic,
  messageToRemove
) {
  const id = cache.identify(topic);
  const { id: topicId, revisionNum: topicRevision } = topic;
  const cacheKey = `${ApolloQueryCacheKey.TOPIC_REPLY_LIST}:${topicId}:${topicRevision}`;
  const fragment = messageDescendantsFragment;
  const fragmentName = 'MessageDescendants';
  const variables = getCachedEntityViewVariables<
    ItemType.MESSAGE,
    MessageDescendantsQueryVariables
  >(
    cache,
    ItemType.MESSAGE,
    { type: MessageViewVariant.STANDARD },
    messageDescendantsQuery,
    cacheKey
  );
  const cachedTopicMessage = cache.readFragment<MessageDescendantsFragment>({
    id,
    fragment,
    fragmentName,
    variables
  });

  if (!cachedTopicMessage) {
    return;
  }

  const originalEdges = cachedTopicMessage?.descendants?.edges ?? [];
  const { repliesCount } = topic;
  const queueToCheckCurrentParent = [];
  queueToCheckCurrentParent.push(messageToRemove);
  const messageIdsToRemove = [];
  const currentMessageReplyCount = messageToRemove?.repliesCount ?? 0;
  while (queueToCheckCurrentParent.length > 0) {
    const currentMessage = queueToCheckCurrentParent.shift();
    originalEdges.forEach(edge => {
      if ((edge.node as ReplyMessage).parent.id === currentMessage.id) {
        queueToCheckCurrentParent.push(edge.node);
      }
    });
    messageIdsToRemove.push(currentMessage.id);
  }
  const updatedEdges = originalEdges.filter(edge => !messageIdsToRemove.includes(edge.node.id));

  const data = {
    ...cachedTopicMessage,
    repliesCount: repliesCount - currentMessageReplyCount - 1,
    descendants: placeholderMessageConnection({
      edges: updatedEdges as MessageEdge[],
      pageInfo: placeholderPageInfo(cachedTopicMessage?.descendants?.pageInfo)
    })
  };

  cache.writeFragment<MessageDescendantsFragment>({
    id,
    fragment,
    fragmentName,
    data,
    variables
  });

  updateSolutionsListCache(tenant, cache, messageToRemove, false, RepliesFormat.Linear);
  updateSolutionMessageCache(cache, messageToRemove, false);
}

/**
 * Get the route and params for the supplied message.
 *
 * @param message the message.
 */
function getMessageRouteAndParams(
  message: MessageLinkFragment | MessageReplyButtonFragment
): MessagePageOrReplyPageAndParams {
  const {
    uid,
    board,
    depth,
    conversation: { topic }
  } = message;

  const { messagePage, messageReplyPage } = ConversationStyleBehaviorHelper.getInstance(board);
  const messageSubject = UrlHelper.determineSlugForMessagePath(message);

  if (depth === 0) {
    return {
      route: messagePage,
      params: {
        boardId: message.board.displayId,
        messageSubject,
        messageId: uid.toString()
      }
    };
  } else {
    return {
      route: messageReplyPage,
      params: {
        boardId: message.board.displayId,
        messageSubject,
        messageId: topic.uid.toString(),
        replyId: uid.toString()
      }
    };
  }
}

/**
 * Clears a message kudos list query cache after a kudo is added or removed
 *
 * @param cache The apollo cache
 * @param messageType the message type
 * @param messageId id to identify the cached query that needs update
 */
function clearMessageKudosCache(
  cache: ApolloCache<{}>,
  messageType: MessageTypes,
  messageId: string
): void {
  cache.modify({
    id: `${messageType}:${messageId}`,
    fields: {
      kudos: (existing, { DELETE }) => {
        return DELETE;
      }
    }
  });
}

function getMessageReplyFullyQualifiedUrl(
  message: MessageReplyButtonFragment,
  router: CustomRouter<EndUserPages, EndUserQueryParams>,
  tenant: Tenant
): string {
  const { route, params } = getMessageRouteAndParams(message) as MessageReplyPagesAndParams;
  const relativeUrlForRoute = router.getRelativeUrlForRoute<MessageReplyPagesAndParams>(
    route,
    params
  );
  return UrlHelper.getFullyQualifiedUrlForPath(tenant, relativeUrlForRoute);
}

/**
 * Checks whether video is present in message teaser.
 */
function isTeaserVideoPresent(message: Message): boolean {
  return (
    message?.videos?.edges?.some(
      video => video?.node?.videoAssociationType === VideoAssociationType.InlineTeaser
    ) ?? false
  );
}

/**
 * Checks whether image is present in message teaser.
 */
function isTeaserImagePresent(message: Message): boolean {
  return (
    message?.images?.edges?.some(
      image =>
        image?.node?.associationType === ImageAssociationType.Teaser ||
        image?.node?.associationType === ImageAssociationType.Cover
    ) ?? false
  );
}

/**
 * Returns the trimmed value and handles nbsp characters.
 * mainly to remove the 'nbsp;' from the processing text i.e. 'Processing Video... \n &nbsp; '
 */
function removeExtraSpaces(content: string): string {
  return content.replaceAll(/&nbsp;|\u00A0/g, ' ');
}

/**
 * Function to determine if the View All Messages page should be indexable or not
 */
function isPageIndexable(router: EndUserRouter): boolean {
  const queryParams = Object.keys(router.getQueryParams());
  return (
    queryParams.length === 0 ||
    (queryParams.length === 1 && queryParams.includes(EndUserQueryParams.AFTER))
  );
}

/**
 * Clear all root message queries from the cache
 *
 * @param cache The Apollo cache
 */
function clearMessageQueriesCache(cache: ApolloCache<{}>): void {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'messages' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'message' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'messagesCount' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'manuallyOrderedArticles' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'previousNextManuallySortedArticles' });
  cache.gc();
}

/**
 * Converts a ContextMessageFragment to a MessageViewFragment
 * @param contextMessage the message to convert
 */
function contextMessageToMessageView(contextMessage: ContextMessageFragment): MessageViewFragment {
  return contextMessage as unknown as MessageViewFragment;
}

/**
 * Converts a MessageActionFragment to a ContextMessageFragment
 * @param messageAction the message fragment to convert.
 */
function messageActionToContextMessage(
  messageAction: MessageActionFragment
): ContextMessageFragment {
  return messageAction as unknown as ContextMessageFragment;
}

/**
 * Converts a ContextMessageFragment to a MessageActionFragment
 * @param contextMessage the message to convert
 */
function contextMessageToMessageActionMenu(
  contextMessage: ContextMessageFragment
): MessageActionMenuFragment {
  return contextMessage as unknown as MessageActionMenuFragment;
}

export {
  buildSolutionsListSorts,
  buildSolutionsListConstraints,
  addReplyToMessageInCache,
  updateSolutionsListCache,
  updateSolutionMessageCache,
  removeCachedReplyFromMessage,
  getMessageRouteAndParams,
  updateReplyToMessageInCache,
  clearMessageKudosCache,
  getMessageReplyFullyQualifiedUrl,
  isTeaserImagePresent,
  isTeaserVideoPresent,
  removeExtraSpaces,
  isPageIndexable,
  contextMessageToMessageView,
  messageActionToContextMessage,
  contextMessageToMessageActionMenu,
  removeCachedDescendantFromTopicMessage,
  clearMessageQueriesCache
};
